<template>
  <div>
    <el-upload
      class="avatar-uploader"
      action="/hiq-admin/admin/file/upload"
      :on-success="onSuccess"
      :file-list="fileList"
      :on-remove="handleRemove"
      :show-file-list="false"
      :before-upload="beforeAvatarUpload"
      accept="image"
    >
      <img v-if="imgUrl" :src="imgUrl" class="avatar" />
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    imgUrl: String,
  },
  data() {
    return {
      imageUrl: '',
      fileList: [],
    }
  },
  methods: {
    exceed() {
      this.$message.error('res.msg')
    },
    onSuccess(res) {
      const item = {
        url: res.data.url,
        name: this.name,
      }
      this.$emit('imgChage', item)
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isLt2M
    },
  },
}
</script>

<style lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>
