export function makeForm(form, obj = {}) {
  for (const key in form) {
    if (obj[key] || obj[key] === 0) {
      form[key] = obj[key]
    } else {
      form[key] = ''
    }
  }
}

// 规则枚举
export const guiZeMeiJu = [{ value: 1, label: '累计答题' }]

export function makeUUID() {
  var d = new Date().getTime()
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
  return uuid
}

export function saveDataToLocalStorage(key, data, isObj) {
  if (isObj) {
    return localStorage.setItem(key, JSON.stringify(data))
  } else {
    return localStorage.setItem(key, data)
  }
}

export function getDataFromLocalStorage(key, isObj) {
  if (isObj) {
    return JSON.parse(localStorage.getItem(key))
  } else {
    return localStorage.getItem(key)
  }
}
