<template>
  <div>
    <el-form :model="myForm" ref="myFormRef" :rules="rules" label-width="100px">
      <el-form-item label="活动名称" prop="projectName">
        <el-input v-model="myForm.projectName" placeholder="请输入活动名称"></el-input>
      </el-form-item>
      <el-form-item label="公司名称" prop="tenantName">
        <el-select v-model="myForm.tenantName" placeholder="请选择公司" :disabled="!!myForm.id">
          <el-option v-for="(item, index) in companyList" :key="index" :label="item.companyName" :value="item.companyCode"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="描述">
        <el-input :autosize="{ minRows: 3, maxRows: 6 }" type="textarea" v-model="myForm.description"></el-input>
      </el-form-item>
    </el-form>
    <div class="btnDiv">
      <el-button @click="quXiaoClick">取 消</el-button>
      <el-button type="primary" @click="diaQueDingClick">确 定</el-button>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import uploadImg from '@/components/common/uploadImg/Index.vue'
import { makeForm } from '@/utils/tools.js'
export default {
  props: ['rowData'],
  components: { uploadImg },
  data() {
    return {
      myForm: {
        projectName: '', // 活动名称
        tenantName: '', // 公司名称
        description: '', // 描述
        id: '',
      },
      rules: {
        projectName: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
        tenantName: [{ required: true, message: '请选择公司', trigger: 'change' }],
      },
      companyList: [],
    }
  },
  created() {},
  mounted() {
    if (this.rowData) {
      console.log('myForm', this.myForm)
      makeForm(this.myForm, this.rowData)
    }
    // 获取公司列表
    this.getGongSiList()
  },
  methods: {
    // 获取公司列表
    async getGongSiList() {
      const res = await this.$API.gongSi.get()
      console.log('getGongSiList', res)
      let { status, data = [], msg = '' } = res
      if (status == 200) {
        this.companyList = data
      } else {
        this.$message.warning(msg)
      }
    },

    // 确定
    diaQueDingClick() {
      this.$refs['myFormRef'].validate(async (valid) => {
        if (valid) {
          // 编辑
          if (this.rowData) {
            const res = await this.$API.huoDong.update(this.myForm)
            if (res.status !== 200) return this.$message.error(res.msg)
          } else {
            const res = await this.$API.huoDong.add(this.myForm)
            if (res.status != 200) return this.$message.error(res.msg)
          }
          this.$emit('diaQueDingClick')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 取消
    quXiaoClick() {
      this.$emit('quXiaoClick')
    },
  },
  destroyed() {},
}
</script>
<style lang="scss" scoped>
.btnDiv {
  display: flex;
  justify-content: flex-end;
  // background: red;
}
</style>
