import { mapGetters } from 'vuex'
export const commonMixin = {
  computed: {
    ...mapGetters(['innerHeight']),
  },

  methods: {
    async deleteClick() {
      return new Promise((resolve) => {
        this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .catch((err) => err)
          .then((res) => {
            if (res !== 'confirm') {
              this.$message.info('已取消删除')
              resolve(false)
            } else {
              resolve(true)
            }
          })
      })
    },
    handleSizeChange(newSize) {
      this.params.pageSize = newSize
      this.getData()
    },
    handleCurrentChange(newPage) {
      this.params.pageNum = newPage
      this.getData()
    },
  },
}

export const resetFrom = {
  methods: {
    resetFrom(fromRef) {
      this.$refs[fromRef].resetFields()
    },
  },
}
